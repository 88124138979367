@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.card {
    background-color: #f2f2f2;
    /* padding: 10px; */
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card h3 {
    color: #333;
    font-size: 18px;
    margin: 0;
  }